import { hardhat, polygon, sepolia, type Chain } from "@wagmi/core/chains";
import { env } from "@env/client.mjs";

export let applicationChain: Chain;
switch (env.NEXT_PUBLIC_BLOCKCHAIN_ENV) {
  // applicationChain = chain.polygon;
  // break;
  case "production":
    applicationChain = polygon;
    break;
  case "test":
    applicationChain = hardhat;
    break;
  case "development":
  case "staging":
  default:
    applicationChain = sepolia;
}
