import * as z from "zod";

export const UserSubscriptionModel = z.object({
  id: z.coerce.number().int(),
  userAddress: z.string(),
  subscriptionId: z.coerce.number().int(),
  startAt: z.coerce.date(),
  expireAt: z.coerce.date(),
  stripeId: z.string(),
  renewActive: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
