import * as z from "zod";
import { PurchaseStatus } from "@prisma/client";

export const StreamingAppointmentInvoiceModel = z.object({
  id: z.coerce.number().int(),
  stripeId: z.string(),
  totalPrice: z.coerce.number().int(),
  totalPriceAsDecimal: z.coerce.number(),
  status: z.nativeEnum(PurchaseStatus),
  expireAt: z.coerce.date(),
  streamingAppointmentId: z.coerce.number().int(),
  userAddress: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
