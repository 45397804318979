import * as z from "zod";

// Helper schema for JSON fields
type Literal = boolean | number | string;
type Json = Literal | { [key: string]: Json } | Json[];
const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodSchema<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
);

export const StreamingProviderModel = z.object({
  address: z.string(),
  customization: z.object({
    logo: z.string(),
    primaryColor: z.string(),
    secondaryColor: z.string(),
  }),
  texts: z.object({
    name: z.string(),
    description: z.string(),
    availableTime: z.string(),
    timezone: z.string(),
  }),
  ratings: jsonSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  googleAppointmentUrl: z.string().nullish(),
});
