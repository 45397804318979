import * as z from "zod";
import { sci_populated_type } from "@prisma/client";

export const SciPopulatedModel = z.object({
  id: z.coerce.number().int().nullish(),
  cartFinished: z.coerce.number().int().nullish(),
  cartExpired: z.coerce.number().int().nullish(),
  cartActive: z.coerce.number().int().nullish(),
  quickIdentifier: z.string().nullish(),
  month: z.coerce.number().int().nullish(),
  week: z.coerce.number().int().nullish(),
  year: z.coerce.number().int().nullish(),
  createdAt: z.coerce.date().nullish(),
  cartItemId: z.coerce.number().int(),
  cartItemQuantity: z.coerce.number().int(),
  priceModifier: z.coerce.number(),
  priceName: z.string().nullish(),
  price: z.coerce.number().int().nullish(),
  priceAsDecimal: z.coerce.number().nullish(),
  type: z.nativeEnum(sci_populated_type).nullish(),
  batchQuantity: z.coerce.number().int().nullish(),
  maxCapacity: z.coerce.number().int().nullish(),
  sectionId: z.string().nullish(),
  sectionName: z.string().nullish(),
  productQuantity: z.coerce.number().int().nullish(),
  productName: z.string().nullish(),
  productPrice: z.coerce.number().nullish(),
});
