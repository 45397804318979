import * as z from "zod";

// Helper schema for JSON fields
type Literal = boolean | number | string;
type Json = Literal | { [key: string]: Json } | Json[];
const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodSchema<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
);

export const StreamingReviewModel = z.object({
  id: z.coerce.number().int(),
  streamingUserAddress: z.string(),
  streamingServiceId: z.coerce.number().int().nullish(),
  title: z.string(),
  description: z.string(),
  ratings: jsonSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
