import * as z from "zod";

export const StreamingFileModel = z.object({
  id: z.coerce.number().int(),
  fileType: z.string(),
  fileSize: z.coerce.number().int(),
  url: z.string(),
  content: z.string().nullish(),
  streamingProviderAddress: z.string(),
  userAddress: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
