import * as z from "zod";

export const SubscriptionModel = z.object({
  id: z.coerce.number().int(),
  title: z.string(),
  description: z.string(),
  basePrice: z.coerce.number().int(),
  basePriceAsDecimal: z.coerce.number(),
  usageBased: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  oneTimeFeeStripeId: z.string().nullish(),
  recurringFeeStripeId: z.string().nullish(),
  usageFee: z.coerce.number().int().nullish(),
  usageFeeAsDecimal: z.coerce.number().nullish(),
});
