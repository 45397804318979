import * as z from "zod";

export const HistoryModel = z.object({
  id: z.coerce.number().int(),
  tableName: z.string(),
  itemId: z.string(),
  description: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
