import * as z from "zod";
import { PurchaseStatus, ShoppingCartLocale } from "@prisma/client";

export const ShoppingCartModel = z.object({
  id: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  userAddress: z.string(),
  expiresAt: z.coerce.date(),
  shippingId: z.string().nullish(),
  status: z.nativeEnum(PurchaseStatus),
  checkoutId: z.string().nullish(),
  quickIdentifier: z.string(),
  locale: z.nativeEnum(ShoppingCartLocale),
  timezone: z.string(),
  subtotal: z.coerce.number(),
  subtotalWithTax: z.coerce.number(),
  organizerIncome: z.coerce.number(),
  month: z.coerce.number().int().nullish(),
  week: z.coerce.number().int().nullish(),
  year: z.coerce.number().int().nullish(),
});
