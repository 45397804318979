/* eslint-disable @typescript-eslint/no-base-to-string */
import Link from "next/link";
import type { ComponentProps } from "react";

type TicketingLinkProps = ComponentProps<typeof Link>;

const PREFIX = "/";
export const getTicketingHref = (href: string) => {
  const prefixIndex = href.indexOf(PREFIX);
  const slicePosition = prefixIndex > -1 ? prefixIndex + PREFIX.length : 0;
  return `${PREFIX}${href.slice(slicePosition)}`;
};

export const TicketingLink = ({
  children,
  href,
  className,
  ...rest
}: TicketingLinkProps) => {
  const hrefString = href.toString();
  const isExternal = hrefString.startsWith("http");
  return (
    <Link
      {...rest}
      className={className}
      href={isExternal ? hrefString : getTicketingHref(hrefString)}
      target={isExternal ? "_blank" : undefined}
      rel={isExternal ? "noopener noreferrer" : undefined}
      onClick={(e) => {
        if (hrefString.startsWith("#")) {
          e.preventDefault();
          e.stopPropagation();
          document
            .getElementById(hrefString.split("#")[1]!)
            ?.scrollIntoView({ behavior: "smooth" });
        }
      }}
    >
      {children}
    </Link>
  );
};
