import * as z from "zod";

export const IdentifiableInformationModel = z.object({
  id: z.coerce.number().int(),
  userAddress: z.string(),
  document: z.string(),
  phone: z.string(),
  cep: z.string(),
  addressNumber: z.string(),
});
