import * as z from "zod";

export const ChatRoomModel = z.object({
  chatRoomId: z.coerce.number().int(),
  providerId: z.coerce.number().int(),
  serviceId: z.coerce.number().int(),
  userId: z.coerce.number().int(),
  startTime: z.coerce.date(),
  endTime: z.coerce.date(),
  name: z.string().nullish(),
  description: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  userAddress: z.string().nullish(),
});
