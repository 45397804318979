import * as z from "zod";

export const StreamingServiceModel = z.object({
  id: z.coerce.number().int(),
  streamingUserAddress: z.string(),
  title: z.string(),
  slug: z.string(),
  description: z.string(),
  duration: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  maxPrice: z.coerce.number().int(),
  maxPriceAsDecimal: z.coerce.number(),
  minPrice: z.coerce.number().int(),
  minPriceAsDecimal: z.coerce.number(),
});
