import * as z from "zod";

export const TicketCourtesyModel = z.object({
  id: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  expiresAt: z.coerce.date(),
  cancelledAt: z.coerce.date().nullish(),
  ticketId: z.coerce.number().int().nullish(),
  ticketBatchId: z.coerce.number().int(),
  email: z.string(),
  claimed: z.boolean(),
});
