import * as z from "zod";

export const MessageModel = z.object({
  messageId: z.coerce.number().int(),
  chatRoomId: z.coerce.number().int(),
  senderId: z.coerce.number().int(),
  content: z.string(),
  timestamp: z.coerce.date(),
  chatRoomChatRoomId: z.coerce.number().int(),
  userAddress: z.string().nullish(),
  readAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
