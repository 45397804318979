import * as z from "zod";

export const GoogleTokensModel = z.object({
  id: z.coerce.number().int(),
  userAddress: z.string(),
  accessToken: z.string(),
  refreshToken: z.string(),
  scope: z.string(),
  expiryDate: z.coerce.date(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
