/* eslint-disable @typescript-eslint/ban-ts-comment */
// Need to have this import and the next-transpile-modules config for this package in order to pass build
import { getTicketingHref } from "@components/TicketingLink";
import { env } from "@env/client.mjs";
import { applicationChain } from "@utils/applicationChain";
import { AuthAdapter } from "@web3auth/auth-adapter";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import {
  Web3AuthConnector,
  Web3AuthConnectorParams,
} from "@web3auth/web3auth-wagmi-connector";

export const Web3GoogleConnector: typeof Web3AuthConnector = (args) => {
  return Web3AuthConnector({
    ...args,
    loginParams: {
      loginProvider: "google",
    },
  });
};

export const Web3FacebookConnector: typeof Web3AuthConnector = (args) => {
  return Web3AuthConnector({
    ...args,
    loginParams: {
      loginProvider: "facebook",
    },
  });
};

export const Web3EmailConnector = (
  args: Web3AuthConnectorParams,
  email: string
) => {
  return Web3AuthConnector({
    ...args,
    loginParams: {
      loginProvider: "email_passwordless",
      login_hint: email,
    },
  });
};

// Create Web3Auth Instance
// const name = "Spaceship Foundation";
const chainConfig = {
  chainId: "0x" + applicationChain.id.toString(16),
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  rpcTarget: applicationChain.rpcUrls.default.http[0] ?? "",
  displayName: applicationChain.name,
  tickerName: applicationChain.nativeCurrency?.name,
  ticker: applicationChain.nativeCurrency?.symbol,
  blockExplorer: applicationChain?.blockExplorers?.[0]?.url ?? "",
};

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig },
});

const web3AuthNoModalInstance = new Web3AuthNoModal({
  clientId: env.NEXT_PUBLIC_WEB3AUTH_CLIENT_ID,
  chainConfig,
  web3AuthNetwork: env.NEXT_PUBLIC_WEB3AUTH_NETWORK,
  privateKeyProvider,
});

/* const privateKeyProvider = new CommonPrivateKeyProvider({
  config: {
    chainConfig: {
      chainId: "0x" + applicationChain.id.toString(16),
      blockExplorerUrl:
        applicationChain.blockExplorers?.default.url ??
        "https://polygonscan.com/",
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      displayName: applicationChain.name,
      rpcTarget: applicationChain.rpcUrls.default.http[0] ?? "",
      tickerName: applicationChain.nativeCurrency?.symbol ?? "MATIC",
      ticker: "MATIC",
    },
  },
}); */

// Add openlogin adapter for customisations
const openloginAdapterInstance = new AuthAdapter({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // privateKeyProvider,
  adapterSettings: {
    uxMode: "redirect",
    redirectUrl:
      typeof window !== "undefined"
        ? window.location.origin +
          "/login/success/" +
          encodeURIComponent(getTicketingHref("/"))
        : "",
    // @ts-ignore
    network: env.NEXT_PUBLIC_WEB3AUTH_NETWORK,
  },
});

// web3AuthInstance.configureAdapter(openloginAdapterInstance);
web3AuthNoModalInstance.configureAdapter(openloginAdapterInstance);
// Add Torus Wallet Plugin (optional)
// const torusPlugin = new TorusWalletConnectorPlugin({
//   torusWalletOpts: {
//     buttonPosition: "bottom-left",
//   },
//   walletInitOptions: {
//     whiteLabel: {
//       theme: {
//         isDark: true,
//         colors: {
//           torusBrand1: "#E35600",
//         },
//       },
//       logoDark: "https://marketplace-base.vercel.app/img/spaceship_icon.png",
//       logoLight:
//         "https://marketplace-base.vercel.app/img/spaceship_icon_white.png",
//       topupHide: true,
//       featuredBillboardHide: true,
//       disclaimerHide: true,
//     },
//     useWalletConnect: true,
//   },
// });

// // web3AuthInstance.addPlugin(torusPlugin);
// void web3AuthNoModalInstance.addPlugin(torusPlugin);

export const setRedirectUrl = (url: string) => {
  openloginAdapterInstance.setAdapterSettings({
    redirectUrl: `${window.location.origin}/login/success/${encodeURIComponent(
      url
    )}`,
  });
};
export const getWeb3AuthInstance = (modal = false) => {
  void modal;
  // if (modal) {
  //   return web3AuthInstance;
  // } else {
  return web3AuthNoModalInstance;
  // }
};
