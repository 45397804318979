import * as z from "zod";
import { ScheduleStatus } from "@prisma/client";

export const StreamingAppointmentModel = z.object({
  id: z.coerce.number().int(),
  userAddress: z.string(),
  roomId: z.string(),
  title: z.string(),
  description: z.string().nullish(),
  startAt: z.coerce.date(),
  duration: z.coerce.number().int(),
  status: z.nativeEnum(ScheduleStatus),
  streamingServiceId: z.coerce.number().int(),
  recurrenceId: z.coerce.number().int().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
