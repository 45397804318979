import * as z from "zod";

export const ResendMailingModel = z.object({
  id: z.coerce.number().int(),
  campaign: z.string(),
  recipient: z.string().nullish(),
  status: z.string().nullish(),
  delivered: z.boolean(),
  opened: z.boolean(),
  resendEmailId: z.string(),
  sentAt: z.coerce.date().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  eventId: z.coerce.number().int(),
});
