import * as z from "zod";

export const RecurrenceModel = z.object({
  id: z.coerce.number().int(),
  every: z.string(),
  window: z.string(),
  startAt: z.coerce.date(),
  endAt: z.coerce.date().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
