import { setRedirectUrl as setWeb3AuthRedirectUrl } from "@components/web3/web3Auth";
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseMutationOptions,
} from "@tanstack/react-query";
import { type ConnectReturnType } from "@wagmi/core";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";

const chainImport = import("@utils/applicationChain");
const coreImport = import("@wagmi/core");
const w3aConnectors = import("@components/web3/web3Auth");

export const useWagmi = () => {
  const { data, ...rest } = useQuery({
    queryKey: ["wagmi-core"],
    queryFn: async () => {
      const [
        // { InjectedConnector },
        // { WalletConnectConnector },
        // { applicationChain },
        // { createClient, configureChains },
        // { publicProvider },
        { applicationChain },
        core,
        { Web3FacebookConnector, Web3GoogleConnector, getWeb3AuthInstance },
      ] = await Promise.all([
        // injectedConnector,
        // walletConnectConnector,
        chainImport,
        coreImport,
        // publicProviders,
        // jsonRPCProviders,
        w3aConnectors,
      ]);

      const config = core.createConfig({
        chains: [applicationChain],
        transports: {
          [applicationChain.id]: core.http(),
        },
      });

      return {
        config,
        core,
        connectors: {
          injected: core.injected(),
          // walletConnect: new WalletConnectConnector({
          //   options: {
          //     projectId: env.NEXT_PUBLIC_WALLETCONNECT_KEY,
          //   },
          //   chains,
          // }),
          google: Web3GoogleConnector({
            web3AuthInstance: getWeb3AuthInstance(false),
          }),
          facebook: Web3FacebookConnector({
            web3AuthInstance: getWeb3AuthInstance(false),
          }),
        },
        chains: config.chains,
      };
    },
    staleTime: Infinity,
  });
  return {
    wagmi: data,
    ...rest,
  };
};

export const useAccount = () => {
  const { wagmi } = useWagmi();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (wagmi)
      return wagmi.core.watchAccount(wagmi.config, {
        onChange: (acc) => {
          if (!acc) localStorage.clear();
          return queryClient.invalidateQueries(["wagmi"]);
        },
      });
  }, [wagmi, queryClient]);
  const { data } = useQuery({
    queryKey: ["wagmi", "account"],
    queryFn: () => {
      if (!wagmi) return null;
      return wagmi.core.getAccount(wagmi.config);
    },
    enabled: !!wagmi,
    initialData: null,
  });
  return (
    data || {
      address: undefined,
      connector: undefined,
      isConnected: false,
      isReconnecting: false,
      isConnecting: false,
      isDisconnected: true,
      status: "disconnected",
    }
  );
};

/* export const useProvider = () => {
  const { wagmi } = useWagmi();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (wagmi)
      return wagmi.core.watchProvider({}, () =>
        queryClient.invalidateQueries(["wagmi"])
      );
  }, [wagmi, queryClient]);
  const { data } = useQuery({
    queryKey: ["wagmi", "provider"],
    queryFn: () => {
      if (!wagmi) return null;
      return wagmi.core.getProvider();
    },
  });
  return data;
}; */

type UseConnectArg = {
  connector: keyof NonNullable<
    ReturnType<typeof useWagmi>["wagmi"]
  >["connectors"];
  reauth?: boolean;
};

type UseConnectOptions = Omit<
  UseMutationOptions<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ConnectReturnType<any> | undefined,
    unknown,
    UseConnectArg,
    unknown
  >,
  "mutationFn" | "mutationKey"
>;

export const useConnect = (opts?: UseConnectOptions) => {
  const { wagmi } = useWagmi();

  const router = useRouter();
  return useMutation({
    mutationKey: ["wagmi", "connect"],
    mutationFn: async ({ connector, reauth = true }: UseConnectArg) => {
      if (router && router.query && typeof router.query.redirect === "string") {
        setWeb3AuthRedirectUrl(router.query.redirect);
      }
      const connection = wagmi?.core.getConnections(wagmi.config)[0];
      if (connection) {
        await wagmi?.core.disconnect(wagmi.config);
      }
      if (reauth) await signOut({ redirect: false });
      localStorage.setItem("wagmi:connector", connector);
      return wagmi?.core.connect(wagmi.config, {
        connector: wagmi?.connectors[connector],
      });
    },
    ...opts,
  });
};
// type WagmiCore = Awaited<typeof core>;
// type WagmiGets = Extract<keyof WagmiCore, `get${string}`>;
//
// type WagmiWatches = Extract<keyof WagmiCore, `watch${string}`>;
