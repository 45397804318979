import * as z from "zod";
import { DiscountType } from "@prisma/client";

export const CouponModel = z.object({
  id: z.coerce.number().int(),
  code: z.string(),
  discountAmount: z.coerce.number(),
  discountType: z.nativeEnum(DiscountType),
  totalUsage: z.coerce.number().int(),
  expiresAt: z.coerce.date().nullish(),
  active: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
