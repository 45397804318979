import * as z from "zod";

export const OrganizerModel = z.object({
  id: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  userAddress: z.string(),
  address: z.string(),
  taxId: z.string(),
});
